export const ERROR_SESSION_CONNECTED = 'Session currently connected'
export const ERROR_SESSION_DISCONNECTED = 'Session currently disconnected'
export const ERROR_SESSION_REJECTED = 'Session Rejected'

export const ERROR_MISSING_JSON_RPC = 'Missing JSON RPC response'
export const ERROR_MISSING_RESULT =
  'JSON-RPC success response must include "result" field'
export const ERROR_MISSING_ERROR =
  'JSON-RPC error response must include "error" field'
export const ERROR_MISSING_METHOD =
  'JSON RPC request must have valid "method" value'
export const ERROR_MISSING_ID = 'JSON RPC request must have valid "id" value'
export const ERROR_MISSING_REQUIRED =
  'Missing one of the required parameters: bridge / uri / session'

export const ERROR_INVALID_RESPONSE = 'JSON RPC response format is invalid'
export const ERROR_INVALID_URI = 'URI format is invalid'
